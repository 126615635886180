/* eslint-disable react/prop-types */
import { graphql, Link } from 'gatsby';
import React from 'react';

import { Layout, SEO } from '../components';
//
import './motorcycles.scss';
import './e-bikes.scss';
import EBikeCard from '../components/EbikeCard';

const EBikesPage = props => {
  const {
    data: {
      allEbikesEdges: { edges },
      sanityEBikesMetadata: { metadata },
      allFile,
    },
  } = props;

  const logo = allFile?.edges?.[0]?.node || null;
  const logoFixedImage = logo?.image?.fixed || null;

  return (
    <Layout>
      <SEO path="/e-bikes" {...metadata} />
      <div className="motorcycles-page e-bikes-padding-bottom">
        <div className="container">
          <section className="section-header">
            <h1 className="section-title n-b-m n-t-m text-black small">
              Shop Certified E-Bikes at JPSuperbikes Superstore
            </h1>
            <ul className="bread-crumbs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="current-bread-crumb">E-bikes</li>
            </ul>
          </section>
        </div>
        <hr />
        <div className="container" style={{ marginTop: '32px' }}>
          <div className="motorcycles-grid">
            {edges.length ? (
              edges.map(({ node }) => (
                <EBikeCard
                  key={node.id}
                  {...node}
                  featuredImage={node.featuredImage.image.asset.fluid}
                  slug={node.slug.current}
                  logoFixedImage={logoFixedImage}
                />
              ))
            ) : (
              <h3 className="n-b-m n-t-m text-black small">No results</h3>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EBikesPage;

export const query = graphql`
  query {
    allEbikesEdges: allSanityEbikes {
      edges {
        node {
          title
          price
          motor
          maxSpeed
          maxRange
          hasLogo
          slug {
            current
          }
          featuredImage {
            image {
              asset {
                fluid(maxWidth: 300) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
    sanityEBikesMetadata {
      metadata {
        title
        keywords
        description
        image {
          asset {
            url
          }
        }
      }
    }
    allFile(filter: { name: { eq: "jpsstore-logo" } }) {
      edges {
        node {
          name
          image: childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
