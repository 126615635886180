/* eslint-disable */

import React from 'react';
//
import ProductCard from '../ProductCard';

const EBikeCard = props => {
  const { slug, maxSpeed, maxRange, motor, ...restProps } = props;

  return (
    <ProductCard
      link={`/e-bikes/${slug}`}
      cardSpecContent={
        <li>
          {maxRange} miles range, {maxSpeed} mph, {motor} Watt
        </li>
      }
      {...restProps}
    />
  );
};

export default EBikeCard;
